.chart {
  height: 70vh;
  max-height: 600px;
  /*width:60vw;
 box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s; */
}

/* Placement of cards */
div.container {
  margin-bottom: 15px;
}

/* DOI badge placement */
img.zenodo {
  vertical-align: middle;
  margin: 0px 10px;
}

/* Get rid of double scroll bar for table */
main.makeStyles-content-12 {
  height: 100%;
}

div.markdown h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.2em;
}

span.has-tooltip {
  cursor: default;
  margin-right: 0.5em;
  text-decoration: underline dotted;
  -webkit-text-decoration: underline dotted;
}

span.has-tooltip:hover {
  text-decoration: none;
}
